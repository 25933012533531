/*

NOTE: This file is created so that all the images can be exported from here

# In future we plan to remove all image files and pass in the imageURLS to the
createImage() function thus saving time

*/

import React from "react";
import Image, { ImageProps } from "next/image";
import { Omit } from "interfaces";

// ---------- IMAGE IMPORTS --------------------
import logo from "./logo.svg";
import enft from "./enft.png";
import wordmark from "./wordmark.svg";
import ic_drop_down from "./ic_drop_down.svg";
import ic_play from "./ic_play.svg";
import ic_upload from "./ic_upload.svg";
import ic_upload_dark from "./ic_upload_dark.svg";
import hero_video_cover from "./hero_video_cover.png";
import yourNFT from "./your_nfts.jpg";

import mintableDark from "./mintable_dark.png";
import openseaDark from "./Opensea_dark.png";
import raribleDark from "./Rarible_dark.png";

import mintableLight from "./mintable_light.png";
import openseaLight from "./Opensea_light.png";
import raribleLight from "./Rarible_light.png";

import heroCircle1 from "./hero_circle_1.svg";
import heroCircle2 from "./hero_circle_2.svg";
import heroCircle3 from "./hero_circle_3.svg";
import heroCircle4 from "./hero_circle_4.svg";

import heroBlur from "./hero_blur.svg";
import heroBlurPhone from "./hero_blur_phone.svg";
import workflowBlur from "./workflow_blur.svg";
import darkBlur from "./dark_blur.svg";

import connectIcon from "./ic_connect.png";
import diligenceIcon from "./ic_diligence.png";
import remintIcon from "./ic_remint.png";

import bankServIcon from "./serv_bank.svg";
import cameraServIcon from "./serv_camera.svg";
import chemicalServIcon from "./serv_chemical.svg";
import expertServIcon from "./serv_expert.svg";
import onsiteServIcon from "./serv_onsite.svg";
import securityServIcon from "./serv_security.svg";
import storageServIcon from "./serv_storage.svg";
import transportServIcon from "./serv_transport.svg";

import pinkPlus from "./pink_plus.png";
import whiteCheck from "./white_check.svg";
import greenCheck from "./green_check.svg";
import crumbArrow from "./crumb_arrow.svg";

import modalClose from "./close_modal.svg";

import appraisalsHero from "./appraisals_hero.png";
import servicesHero from "./services_hero.png";
import developerHero1 from "./developer_hero.png";
import developerHero2 from "./developer_hero2.png";
import developerHero3 from "./developer_hero3.png";

import burger from "./burger.svg";
import closeNav from "./close_nav.svg";

import facebook from "./facebook.svg";
import apple from "./apple.svg";
import google from "./google.svg";
import metamask from "./metamask.svg";
import coinbase from "./coinbase.png";
import walletconnect from "./walletconnect.png";
import trezor from "./trezor.png";
import lodger from "./lodger.png";
import trademarkia from "./trademarkia_logo.png";

// Login pages
import eyeOpenIcon from "./eye_open.svg";
import eyeCloseIcon from "./eye_close.svg";

// Contact us Page
import headphone from "./headphone.png";

// About us Page
import aboutUs from "./about_us.png";
import geoff from "./geoff.png";
import alex from "./alex.png";
import raj from "./raj.png";
import brent from "./brent.png";

// How it works pages
import trustBadge from "./trust_badge.png";
import trustBadgeGold from "./trust_gold.png";
import trustBadgeSilver from "./trust_silver.png";
import validateIcon from "./ic_validate.png";
import uploadIcon from "./ic_upload.png";
import shieldIcon from "./ic_shield.png";
import benefitCreators from "./benefit_creators.svg";
import benefitCollectors from "./benefit_collectors.svg";
import benefitExchanges from "./benefit_exchanges.svg";

// Payment Pages
import gradientLoader from "./gradient_loader.png";
import successIcon from "./success.png";

type ImgProps = Omit<ImageProps, "src">;

// -------------- EXPORT FUNCTION -----------------
export const createImage =
  (image: StaticImageData, options: ImgProps = {}) =>
  (props: ImgProps) =>
    <Image {...options} {...props} src={image} />;

// ---------------- IMAGE EXPORTS ------------------
export const Logo = createImage(logo);
export const ENFT = createImage(enft);
export const Wordmark = createImage(wordmark);
export const DropDownArrow = createImage(ic_drop_down);
export const PlayIcon = createImage(ic_play);
export const UploadIconDark = createImage(ic_upload_dark);
export const HeroVideoCover = createImage(hero_video_cover);
export const YourNFT = createImage(yourNFT);

export const MintableDark = createImage(mintableDark);
export const OpenseaDark = createImage(openseaDark);
export const RaribleDark = createImage(raribleDark);

export const UploadIcon = createImage(ic_upload);
export const MintableLight = createImage(mintableLight);
export const OpenseaLight = createImage(openseaLight);
export const RaribleLight = createImage(raribleLight);

export const HeroCircle1 = createImage(heroCircle1);
export const HeroCircle2 = createImage(heroCircle2);
export const HeroCircle3 = createImage(heroCircle3);
export const HeroCircle4 = createImage(heroCircle4);

export const HeroBlur = createImage(heroBlur);
export const WorkflowBlur = createImage(workflowBlur);
export const HeroBlurPhone = createImage(heroBlurPhone);
export const DarkBlur = createImage(darkBlur);

export const ConnectIcon = createImage(connectIcon);
export const DiligenceIcon = createImage(diligenceIcon);
export const RemintIcon = createImage(remintIcon);

export const BankServIcon = createImage(bankServIcon);
export const CameraServIcon = createImage(cameraServIcon);
export const ChemicalServIcon = createImage(chemicalServIcon);
export const ExpertServIcon = createImage(expertServIcon);
export const OnsiteServIcon = createImage(onsiteServIcon);
export const SecurityServIcon = createImage(securityServIcon);
export const StorageServIcon = createImage(storageServIcon);
export const TransportServIcon = createImage(transportServIcon);

export const PinkPlus = createImage(pinkPlus);
export const WhiteCheck = createImage(whiteCheck);
export const GreenCheck = createImage(greenCheck);
export const CrumbArrow = createImage(crumbArrow);

export const ModalClose = createImage(modalClose);
export const AppraisalsHero = createImage(appraisalsHero);
export const ServicesHero = createImage(servicesHero);
export const DeveloperHero1 = createImage(developerHero1);
export const DeveloperHero2 = createImage(developerHero2);
export const DeveloperHero3 = createImage(developerHero3);

export const Burger = createImage(burger);
export const CloseNav = createImage(closeNav);

export const FacebookLogo = createImage(facebook);
export const AppleLogo = createImage(apple);
export const GoogleLogo = createImage(google);
export const MetamaskLogo = createImage(metamask);
export const CoinbaseLogo = createImage(coinbase);
export const WalletconnectLogo = createImage(walletconnect);
export const TrezorLogo = createImage(trezor);
export const LodgerLogo = createImage(lodger);
export const TrademarkiaLogo = createImage(trademarkia);

// Login pages
export const EyeOpenIcon = createImage(eyeOpenIcon, { color: "white" });
export const EyeCloseIcon = createImage(eyeCloseIcon, { color: "white" });

// Contact us Page
export const HeadphoneLogo = createImage(headphone);

// About us Page
const boardStyle: ImgProps = {
  width: "100px",
  height: "100px",
  objectFit: "cover",
};

export const AboutUsLogo = createImage(aboutUs);
export const AboutGeoff = createImage(geoff, boardStyle);
export const AboutAlex = createImage(alex, boardStyle);
export const AboutRaj = createImage(raj, boardStyle);
export const AboutBrent = createImage(brent, boardStyle);

// how it works pages

export const ValidateIcon = createImage(validateIcon);
export const UploadDocIcon = createImage(uploadIcon);
export const ShieldIcon = createImage(shieldIcon);
export const TrustBadge = createImage(trustBadge);
export const TrustBadgeGold = createImage(trustBadgeGold);
export const TrustBadgeSilver = createImage(trustBadgeSilver);
export const BenefitCreators = createImage(benefitCreators);
export const BenefitCollectors = createImage(benefitCollectors);
export const BenefitExchanges = createImage(benefitExchanges);

export { workflowBlur };

// payments pages
export const GradientLoader = createImage(gradientLoader, {
  width: "50px",
  height: "50px",
});
export const SuccessIcon = createImage(successIcon);
