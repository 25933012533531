import { signIn } from "./auth"
import { client } from "config/axiosClient";

export const checkDappLogin = async (account: string, setUser): Promise<boolean> => {
  let apiToken = ''
  let walletType = ''
  if (typeof window !== 'undefined') {
    apiToken = localStorage.getItem('apiToken')
    if (localStorage.getItem("userDetails"))
      walletType = JSON.parse(localStorage.getItem("userDetails"))[
        "wallet_type"
      ];
  }
  if (account && apiToken !== '') {
    const mockUser = {
      firstName: null,
      lastName: null,
      email: null,
      walletType: 'metamask',
      walletAddress: account,
    }
    setUser(mockUser)
    await signIn(mockUser, setUser)
    return true
  }
  else if (walletType !== '' && walletType !== undefined) {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('apiToken')
      localStorage.removeItem('userDetails')
    }
    setUser({
      firstName: null,
      lastName: null,
      email: null,
      walletType: null,
      walletAddress: null,
    })
    return false
  }
}

export const checkSignedNonce = async (signedNonce: string) => {

  const walletAddress = JSON.parse(localStorage.getItem('userDetails'))['wallet_address']

  const body = {
    wallet_address: walletAddress,
    signed_nonce: signedNonce,
  }

  try {
    await client.post("/user/login", body);
  } catch (error) {
    throw {
      message: error.response.data.status + ": " + error.response.data.msg
    };
  }
}