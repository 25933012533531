import { ConnectIcon, PinkPlus, RemintIcon } from "assets";
import { useModal } from "contexts/modal";
import { useUser } from "contexts/user";
import { useRouter } from "next/router";
import { FC } from "react";
import { toast } from "react-toastify";
import { isSignedIn } from "services/auth/auth";
import classes from "styles/modals/Mint.module.scss";

interface Props {}

const Mint: FC = () => {
  const { setUser } = useUser()
  const { push } = useRouter()
  const { closeModal } = useModal();

  const comingSoon = (e) => {
    e.preventDefault()
    // toast.warning("Coming Soon!")
    if (isSignedIn(setUser)) push("/create?step=1")
    else push("/login")
    closeModal()
  }

  return (
    <div className={classes.container}>
      <h1>Does your item already exist?</h1>
      <p className={classes.desc}>
        Is the item live on the blockchain or is it a new item you want to make
        ?
      </p>
      <div className={classes.flex}>
        <div className={classes.prompt}>
          <div className={classes.left}>
            <div className={classes.icon}>
              <ConnectIcon />
            </div>
            <p>NFT is already there in my wallet</p>
          </div>
          <button onClick={comingSoon}>Connect Wallet</button>
        </div>
        <div className={classes.prompt}>
          <div className={classes.left}>
            <div className={[classes.icon, classes.remint].join(" ")}>
              <RemintIcon />
            </div>
            <p>I want to create a new item</p>
          </div>
          <button onClick={comingSoon}>Create Item</button>
        </div>
      </div>
    </div>
  );
};

export default Mint;
