import { ModalClose } from "assets";
import { createContext, FC, useContext, useState } from "react";
import classes from "styles/components/Modal.module.scss";

const ModalContext = createContext({
  Component: undefined as undefined | FC,
  openModal: (Component: FC) => {},
  closeModal: () => {},
  isModalOpen: false,
});

export const ModalProvider = ({ children, ...props }) => {
  const [{ Component }, setModalComponent] = useState<{
    Component: undefined | FC;
  }>({
    Component: undefined,
  });
  const closeModal = () => setModalComponent({ Component: undefined });
  const openModal = (Component: FC) => setModalComponent({ Component });
  return (
    <ModalContext.Provider
      {...props}
      value={{
        Component: Component,
        openModal,
        closeModal,
        isModalOpen: !!Component,
      }}
    >
      <>
        {Component ? (
          <>
            <div className={classes.backdrop} onClick={closeModal}></div>
            <div className={classes.container}>
              <div className={classes.innercontainer}>
                <div onClick={closeModal} className={classes.close}>
                  <ModalClose />
                </div>
                <div className={classes.component}>
                  <Component />
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {children}
      </>
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
