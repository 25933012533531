import { Logo, Wordmark } from "assets";
import Link from "next/link";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createNewsletter } from "services/forms";
import { Formik, Field, Form, ErrorMessage } from "formik";
import classes from "styles/components/Footer.module.scss";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

// Dynamic copyright
const currentYear = new Date().getFullYear();

export default function Footer() {
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.left}>
          <div className={classes.logo}>
            <Logo />
            <Wordmark />
          </div>
          <p className={classes.tagline}>
            World's first blockchain based copyrighting service transforming
            NFTs into enforceable intellectual property assets.
          </p>
          <div className={classes.linksContainer}>
            <div className={classes.links}>
              <Link href="/terms">
                <p>Terms</p>
              </Link>
              <Link href="/privacy">
                <p>Privacy Policy</p>
              </Link>
              {/* <Link href="/affiliates">
                <p>Affiliates</p>
              </Link> */}
            </div>
          </div>
          <p className={classes.copyright}>
            © {currentYear} EnforceableNFT, Inc.
          </p>
        </div>
        <div className={classes.right}>
          <h3>Stay In The Loop</h3>
          <p className={classes.joinMail}>
            Join our mailing list to receive valuable articles about the latest
            updates in the world of eNFT™ and intellectual property rights
            affecting them.
          </p>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={yup.object({
              email: yup.string().email("Invalid Email format"),
            })}
            onSubmit={({ email }, { resetForm }) => {
              if (email !== "") {
                createNewsletter(email).then(() => {
                  toast.success("Signed up!");
                });
                resetForm();
              }
            }}
          >
            <Form className={classes.mailForm}>
              <div style={{ flex: 2 }}>
                <Field
                  name="email"
                  className={classes.formInput}
                  type="text"
                  placeholder="Email Address"
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <div style={{ fontSize: "10px" }} className="errMsg">
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <button
                style={{ flex: 1 }}
                type="submit"
                className={classes.signUpBtn}
              >
                Sign Up
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}
