import { useModal } from "contexts/modal";
import { useScroll } from "contexts/scroll";
import { useTheme } from "contexts/theme";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import Footer from "./Footer";
import Header from "./Header";

import { useUser } from "contexts/user";
import { logOutUser } from "config/firebase";
import { isSignedIn } from "services/auth/auth";
import { checkDappLogin, checkSignedNonce } from "services/auth/authDapp";
import { toast } from "react-toastify";

import { useEthers } from "@usedapp/core";
import Head from "next/head";

interface Props {}

const Layout: NextPage<Props> = ({ children }) => {
  const { isDark } = useTheme();
  const { query, push } = useRouter();
  const { scrollTo, refs } = useScroll();
  const { isModalOpen } = useModal();
  const { account, library } = useEthers();
  const { setUser } = useUser();
  const signOut = () => {
    push("/");
    logOutUser(setUser);
  };

  useEffect(() => {
    if (isModalOpen) disableBodyScroll(document);
    else enableBodyScroll(document);
  }, [isModalOpen]);

  useEffect(() => {
    if (query.scroll) scrollTo(query.scroll.toString());
  }, [refs]);

  useEffect(() => {
    isSignedIn(setUser);
  }, []);

  const checkMetamask = (account: string) => {
    checkDappLogin(account, setUser)
      .then((bool) => {
        if (!bool && account) push("/");
        if (bool && typeof window !== "undefined") {
          const nonce = JSON.parse(localStorage.getItem("userDetails"))[
            "nonce"
          ];
          const signer = library.getSigner(account);
          signer.signMessage(nonce).then((str) => void checkSignedNonce(str));
          toast.success("Metamask login successful!")
        }
      })
      .catch((err) => {
        if (err.message) toast.error(err.message);
        else toast.error(err);
      });
  };

  useEffect(() => {
    checkMetamask(account);
  }, [account]);

  return (
    <div className={["root", isDark ? "dark" : "light"].join(" ")}>
      <Header signOut={signOut} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
