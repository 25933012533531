import { Mainnet, Config } from '@usedapp/core'

export const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: 'https://mainnet.infura.io/v3/b91facb68a1744e9b657c80f15aa34ad',
  },
}


