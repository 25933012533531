import {
  createContext,
  FC,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

const GlobalsContext = createContext({
  isNavOpen: false,
  setIsNavOpen: (() => {}) as Dispatch<SetStateAction<boolean>>,
});

export const useGlobals = () => useContext(GlobalsContext);

export const GlobalsProvider: FC = (props) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  return (
    <GlobalsContext.Provider {...props} value={{ isNavOpen, setIsNavOpen }} />
  );
};
