import { DAppProvider } from "@usedapp/core";
import Layout from "components/Layout";
import { config } from "config/dappClient";
import { GlobalsProvider } from "contexts/globals";
import { ModalProvider } from "contexts/modal";
import { ScrollProvider } from "contexts/scroll";
import { ThemeProvider } from "contexts/theme";
import { UserProvider } from "contexts/user";
import { AppProps } from "next/app";
import Head from "next/head";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "styles/index.scss";

toast.configure({ theme: "colored" });

export default function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/61f3d7839bd1f31184d9ca28/1fqg83n9c';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);
  return (
    <ScrollProvider>
      <ThemeProvider>
        <ModalProvider>
          <GlobalsProvider>
            <UserProvider>
              <DAppProvider config={config}>
                <Layout>
                  <Head>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-5JNCF64C0B"></script>
                    <script
                      dangerouslySetInnerHTML={{
                        __html: `
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', 'G-5JNCF64C0B', { page_path: window.location.pathname });
                        `,
                      }}
                    >
                    </script>
                    <script
                      dangerouslySetInnerHTML={{
                        __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2896840,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
                      }}
                    ></script>
                    <link
                      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900"
                      rel="stylesheet"
                    />
                    <meta charSet="UTF-8" />
                    <meta
                      name="viewport"
                      content="width=device-width,initial-scale=1,shrink-to-fit=no"
                    />
                    <meta
                      name="description"
                      content="Enforceable is the world's first blockchain based copyrighting service transforming NFTs into enforceable intellectual property assets."
                    />
                    <meta
                      name="keywords"
                      content="Enforceable, EnforceableNFT, Enforceable, NFT, Blockchain, Crypto, IP, Intellectual Property, Law, Copyright, Trust, Badge, Legal"
                    />
                    <meta name="author" content="Enforceable" />
                    <meta name="copyright" content="EnforceableNFT, Inc." />
                    <meta name="language" content="en" />
                    <meta name="url" content="https://www.enforceable.com" />
                    <meta
                      name="category"
                      content="NFTs, Crypto, Law, Intellectual Property"
                    />
                    <meta name="coverage" content="Worldwide" />
                    <meta name="rating" content="General" />
                    <meta name="og:email" content="cs@enforceable.com" />
                    <meta name="og:country-name" content="USA" />
                    <meta
                      name="og:region"
                      content="California, Palo Alto, Menlo Park, Mountain View, United States, America"
                    />
                    <meta
                      property="og:site_name"
                      content="Enforceable- Increasing marketability of your NFTs"
                    />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta property="og:type" content="website" />
                    <meta
                      property="og:title"
                      content="Enforceable- Increasing marketability of your NFTs"
                    />
                    <meta
                      property="og:description"
                      content="Enforceable is the world's first blockchain based copyrighting service transforming NFTs into enforceable intellectual property assets."
                    />
                    <meta
                      property="og:url"
                      content="https://www.enforceable.com"
                    />
                    <meta
                      property="og:site_name"
                      content="Enforceable- Increasing marketability of your NFTs"
                    />
                    <meta name="twitter:card" content="website" />
                    <meta
                      name="twitter:site"
                      content="Enforceable- Increasing marketability of your NFTs"
                    />
                    <meta
                      name="twitter:title"
                      content="Enforceable- Increasing marketability of your NFTs"
                    />
                    <meta
                      name="twitter:description"
                      content="Enforceable is the world's first blockchain based copyrighting service transforming NFTs into enforceable intellectual property assets."
                    />
                    <meta name="twitter:creator" content="@enforceable" />
                    <meta
                      itemProp="name"
                      content="Enforceable- Increasing marketability of your NFTs"
                    />
                    <meta
                      itemProp="description"
                      content="Enforceable is the world's first blockchain based copyrighting service transforming NFTs into enforceable intellectual property assets."
                    />
                    <meta name="theme-color" content="#6AEBBC" />
                    <link rel="canonical" href="https://www.enforceable.com" />
                    <meta
                      property="twitter:image"
                      content="http://assets.web.enforceable.com/Enforceable-Card.jpeg"
                    />
                    <meta
                      property="og:image"
                      itemProp="image"
                      content="http://assets.web.enforceable.com/Enforceable-Card.jpeg"
                    />
                    <link
                      rel="apple-touch-icon"
                      href="https://assets.web.enforceable.com/favicon.jpg"
                    />
                    <link
                      rel="icon"
                      href="https://assets.web.enforceable.com/favicon.jpg"
                    />
                    <title>
                      Enforceable- Increasing marketability of your NFTs
                    </title>
                  </Head>
                  <Component {...pageProps} />
                </Layout>
              </DAppProvider>
            </UserProvider>
          </GlobalsProvider>
        </ModalProvider>
      </ThemeProvider>
    </ScrollProvider>
  );
}
