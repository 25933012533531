import { Burger, CloseNav, DropDownArrow, Logo, Wordmark } from "assets";
import { useGlobals } from "contexts/globals";
import { useModal } from "contexts/modal";
import { useScroll } from "contexts/scroll";
import { useUser } from "contexts/user";
import useScrollPosition from "hooks/useScrollPosition";
import Mint from "modals/Mint";
import { NextPage } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, Fragment, useEffect, useState } from "react";
import classes from "styles/components/Headers.module.scss";

interface NavItem {
  label: string;
  link?: string;
  action?: () => void;
  menu?: {
    label: string;
    link?: string;
    action?: () => void;
  }[];
}

interface Props {
  signOut: () => void;
}

const Header: NextPage<Props> = ({ signOut }) => {
  const scrolled = useScrollPosition();
  const { redirectScroll } = useScroll();
  const { pathname } = useRouter();
  const { openModal } = useModal();
  const [openMenu, setOpenMenu] = useState("");
  const { user } = useUser();
  const [loginNavItems, setLoginNavItems] = useState<NavItem[]>([
    { label: "Partner Program", link: "/partner_program" },
    { label: "Login", link: "/login" },
  ]);

  const navItems: NavItem[] = [
    // TODO: Change this back to original later
    // { label: "Mint a NFT", action: () => openModal(Mint) },
    // { label: "NFT Appraisals", link: "/appraisals" },
    // { label: "Vaulting Services", link: "/services" },
    {
      label: "How it works",
      menu: [
        { label: "How it works", link: "/how_it_works" },
        { label: "About Us", link: "/about" },
        { label: "Contact Us", link: "/contact" },
        { label: "Partner Program", link: "/partner_program" },
        { label: "Developer API", link: "/developer" },
        { label: "Mint a NFT", action: () => openModal(Mint) },
        { label: "NFT Appraisals", link: "/appraisals" },
        { label: "Vaulting Services", link: "/services" },
      ],
    },
  ];
  const { isNavOpen, setIsNavOpen } = useGlobals();

  // if (pathname.includes("/create")) return <></>;

  useEffect(() => {
    if (!isNavOpen) {
      // dont display if nav isn't in mobile
      setLoginNavItems([]);
      return;
    }
    if (!(user.email || user.walletAddress)) {
      setLoginNavItems([
        { label: "Partner Program", link: "/partner_program" },
        { label: "Login", link: "/login" },
      ]);
    } else if (user.email) {
      setLoginNavItems([{ label: "Logout", action: () => signOut() }]);
    }
  }, [user, isNavOpen]);

  return (
    <header
      className={[
        classes.container,
        scrolled > 0 ? classes.scrolled : classes.notScrolled,
        isNavOpen ? classes.navOpen : classes.navClosed,
        pathname.includes("/create") ? classes.workflow : "",
      ].join(" ")}
    >
      <div className={classes.innerContainer}>
        {/* TODO: Change this later */}
        <div style={{ display: "flex", gap: "60px" }}>
          <Link href="/">
            <div className={classes.logo} onClick={() => setIsNavOpen(false)}>
              <Logo />
              <Wordmark />
            </div>
          </Link>
          <nav
            className={[
              classes.nav,
              isNavOpen ? classes.open : classes.closed,
            ].join(" ")}
          >
            {[...navItems, ...loginNavItems].map(
              ({ label, link, menu, action }) => {
                const Wrapper: FC = action
                  ? (props) => <div {...props} onClick={action} />
                  : link
                  ? (props) => <Link {...props} key={label} href={link} />
                  : Fragment;
                return (
                  <div
                    onClick={() => {
                      if (!menu) setIsNavOpen(false);
                      setOpenMenu((p) => (p === link ? "" : link));
                    }}
                    className={[
                      classes.navItem,
                      menu ? classes.isMenu : classes.isLink,
                    ].join(" ")}
                    key={label}
                  >
                    <Wrapper>
                      <div className={classes.clickArea}>
                        <p className={classes.navText}>{label}</p>
                        {menu && <DropDownArrow />}
                      </div>
                    </Wrapper>
                    {!menu && <div className={classes.underline} />}
                    {menu && (
                      <div
                        className={[
                          classes.dropdown,
                          openMenu === link ? classes.active : classes.inactive,
                        ].join(" ")}
                      >
                        {menu.map(({ label, link, action }) => (
                          <div key={label}>
                            {action && (
                              <div
                                onClick={() => {
                                  action();
                                  setIsNavOpen(false);
                                }}
                                className={classes.dropItem}
                              >
                                <p className={classes.dropText}>{label}</p>
                              </div>
                            )}
                            {link && (
                              <Link key={label} href={link}>
                                <div
                                  onClick={() => setIsNavOpen(false)}
                                  className={classes.dropItem}
                                >
                                  <p className={classes.dropText}>{label}</p>
                                </div>
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </nav>
        </div>
        <div className={classes.actions}>
          {!(user.email || user.walletAddress) && (
            <>
              <Link href="/login">
                <p className={classes.login}>Login</p>
              </Link>
              {/* <Link href="/partner_program">
                <button className={classes.register}>Partner Program</button>
              </Link> */}
            </>
          )}
          {user.email && (
            <p className={classes.login} onClick={() => signOut()}>
              Logout
            </p>
          )}
        </div>
        <div className={classes.burger} onClick={() => setIsNavOpen((p) => !p)}>
          {isNavOpen ? <CloseNav /> : <Burger />}
        </div>
      </div>
    </header>
  );
};

export default Header;
