import { useRouter } from "next/router";
import { createContext, FC, RefObject, useContext, useState } from "react";

const ScrollContext = createContext({
  createScroller: (key: string, ref: any) => { },
  scrollTo: (key: string) => { },
  refs: {} as { [key: string]: RefObject<any> },
  redirectScroll: (route: string, key: string) => { },
});

export const useScroll = () => useContext(ScrollContext);

export const ScrollProvider: FC = (props) => {
  const [refs, setRefs] = useState<{ [key: string]: RefObject<any> }>({});
  const { pathname, push } = useRouter();

  const createScroller = (key: string, ref: RefObject<any>) =>
    setRefs((p) => ({ ...p, [key]: ref }));

  const scrollTo = (key: string, offset?: number) =>
    scrollToRef(refs[key], offset);

  const scrollToRef = (ref: RefObject<any>, offset = 100) =>
    ref?.current?.offsetTop && typeof window !== "undefined" && window.scrollTo(0, ref.current.offsetTop - offset);

  const redirectScroll = (route: string, key: string) =>
    pathname === route ? scrollTo(key) : push(`${route}?scroll=${key}`);

  return (
    <ScrollContext.Provider
      {...props}
      value={{ createScroller, scrollTo, refs, redirectScroll }}
    />
  );
};
