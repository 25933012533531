import { User } from "contexts/user";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { signIn } from "services/auth/auth";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_apiKey,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_authDomain,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_projectId,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_storageBucket,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_messagingSenderId,
  appId: process.env.NEXT_PUBLIC_FIREBASE_appId,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_measurementId,
};

const app = initializeApp(firebaseConfig);
export default app;

const auth = getAuth();
const provider = new GoogleAuthProvider();

export const logOutUser = (setUser) => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      if (typeof window !== "undefined") {
        localStorage.removeItem("apiToken");
        localStorage.removeItem("userDetails");
      }
      setUser({
        firstName: null,
        lastName: null,
        email: null,
        walletType: null,
        walletAddress: null,
      });
    })
    .catch((error) => {
      // An error happened.
    });
};

export const createUser = async (
  fname,
  lname,
  email,
  password,
  user,
  setUser
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    // Signed in
    // const user = userCredential.user;
    const mockUser = {
      ...user,
      firstName: fname,
      lastName: lname,
      email: email,
    };
    const token = await auth.currentUser.getIdToken();
    return signIn(mockUser, setUser, token);
  } catch (error) {
    if (error.code === "auth/user-not-found")
      throw { message: "Username/Password combination is incorrect" };
    else if (error.code === "auth/email-already-in-use")
      throw { message: "Email is already in use. Please log in." };
    throw error;
  }
};

export const loginWithPassword = async (
  email: string,
  password: string,
  user,
  setUser
) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    // Signed in
    // const user = userCredential.user;
    const mockUser = {
      ...user,
      email: email,
    };
    const token = await auth.currentUser.getIdToken();
    return signIn(mockUser, setUser, token);
  } catch (error) {
    if (error.code === "auth/user-not-found")
      throw { message: "Username/Password combination is incorrect" };
    else if (error.code === "auth/email-already-in-use")
      throw { message: "Email is already in use. Please log in." };
    throw error;
  }
};

export const loginWithGoogle = async (user, setUser) => {
  try {
    const result = await signInWithPopup(auth, provider);
    let mockUser = { ...user };

    if (result?.user) {
      mockUser["firstName"] = result.user.displayName?.split(" ")[0] ?? "";
      mockUser["lastName"] = result.user.displayName?.split(" ")[result.user.displayName?.split(" ").length - 1] ?? "";
      mockUser["email"] = result.user.email;
    }
    const token = await auth.currentUser.getIdToken();

    return signIn(mockUser, setUser, token);
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email: string): Promise<boolean> => {
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    return false
  }
}
