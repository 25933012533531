import { recaptchaClient } from "config/axiosClient";

export const createDevApi = async (
  name: string,
  email: string,
  message: string
) => {
  const body = {
    name: name,
    email: email,
    message: message,
  };
  await recaptchaClient.post("/form/devapi", body);
};

interface QuoteData {
  firstName: string;
  lastName: string;
  phone: string;
  title: string;
  email: string;
  company: string;
  postcode: string;
  comment: string;
}

export const createAppraisal = async (data: QuoteData) => {
  const body = {
    first_name: data.firstName,
    last_name: data.lastName,
    phone: data.phone,
    title: data.title,
    email: data.email,
    company: data.company,
    post_code: data.postcode,
    comment: data.comment,
  };
  await recaptchaClient.post("/form/portfolio", body);
};

export const createQuote = async (data: QuoteData) => {
  const body = {
    first_name: data.firstName,
    last_name: data.lastName,
    phone: data.phone,
    title: data.title,
    email: data.email,
    company: data.company,
    post_code: data.postcode,
    comment: data.comment,
  };
  await recaptchaClient.post("/form/vault", body);
};

export const createNewsletter = async (email: string) => {
  const body = {
    email: email,
  };
  await recaptchaClient.post("/form/newsletter", body);
};

interface PartnerData {
  firstName: string;
  lastName: string;
  jobTitle: string;
  workEmail: string;
  companyName: string;
  interestInNFTOfferings: string;
}

export const createPartner = async (data: PartnerData) => {
  const body = {
    work_email: data.workEmail,
    first_name: data.firstName,
    last_name: data.lastName,
    job_title: data.jobTitle,
    company_name: data.companyName,
    comment: data.interestInNFTOfferings,
  };
  await recaptchaClient.post("/form/partner", body);
};
