import { FC } from "react";
import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

const ThemeContext = createContext({ isDark: true, toggleTheme: () => {} });

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider: FC = (props) => {
  const [isDark, setIsDark] = useState(true);
  const toggleTheme = () => setIsDark((p) => !p);
  return <ThemeContext.Provider {...props} value={{ isDark, toggleTheme }} />;
};
