import { client, recaptchaClient } from "config/axiosClient";
import { User } from "contexts/user";

export const signIn = async (user: User, setUser, token?: string) => {
  const body = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    wallet_type: user.walletType,
    wallet_address: user.walletAddress,
  };

  let config = {};

  if (token)
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

  const handleResponse = (response) => {
    let address = response.data.body.user.wallet_address;
    if (address === "") address = null;
    setUser({
      firstName: response.data.body.user.first_name,
      lastName: response.data.body.user.last_name,
      email: response.data.body.user.email,
      walletAddress: address,
    });
    if (typeof window !== "undefined") {
      localStorage.setItem("apiToken", response.data.body.token);
      localStorage.setItem(
        "userDetails",
        JSON.stringify(response.data.body.user)
      );
    }
  };

  try {
    const response = await recaptchaClient.post("/user/signup", body, config);
    handleResponse(response);
  } catch (error) {
    if (error.response.status === 409) handleResponse(error.response);
    else if (error.response.status === 404)
      throw {
        message: "User does not exist",
      };
    else {
      throw {
        message: error.response.data.status + ": " + error.response.data.msg,
      };
    }
  }
};

export const isSignedIn = (setUser): boolean => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("apiToken")) {
      const user = JSON.parse(localStorage.getItem("userDetails"));
      setUser({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        walletType: user.wallet_type,
        walletAddress: user.wallet_address,
      });
      return true;
    }
  }
  return false;
};
