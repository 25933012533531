import {
  createContext,
  FC,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

export interface User {
  firstName?: string;
  lastName?: string;
  email?: string;
  walletType?: string;
  walletAddress?: string;
}

const initialUser: User = {
  firstName: "",
  lastName: "",
  email: "",
}

const UserContext = createContext({
  user: initialUser,
  setUser: (() => {}) as Dispatch<SetStateAction<User>>,
});

export const useUser = () => useContext(UserContext);

export const UserProvider: FC = (props) => {
  const [user, setUser] = useState(initialUser);
  return (
    <UserContext.Provider {...props} value={{ user, setUser }} />
  );
};
